import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./activitybed.png";
import togle from "./togle.svg";

function MobileNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null); // To track active main menu item

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function toggleMenu() {
    setIsOpen(!isOpen);
    setActiveItem(null); // Close any open sub-dropdown when toggling main menu
  }

  function handleMainItemClick(item) {
    if (activeItem === item) {
      setActiveItem(null); // Close sub-dropdown if clicking on the active item again
    } else {
      setActiveItem(item); // Set active item to show its sub-dropdown
    }
    scrollToTop();
  }

  return (
    <>
      <div className="mobile-navbar">
        <div className="mobile_navbar_inner_first">
          <nav className="navbar mobile-navbar">
            <div className="navbar-toggle" onClick={toggleMenu}>
              <img src={togle} alt="Toggle Menu" />
            </div>
          </nav>
          <div className="mobile-logo">
            <Link to="/" onClick={scrollToTop}>
              <img src={logo} alt="Logo" />
            </Link>
          </div>
        </div>
        <div>
          <button className="navbar-button">Login</button>
        </div>
        <div className={`navbar-menu ${isOpen ? "active" : ""}`}>
          <ul>
            <li
              onClick={() => handleMainItemClick("solution")}
              className={activeItem === "solution" ? "active" : ""}
            >
              Solution
              {activeItem === "solution" && (
                <div className="sub-dropdown">
                  <ul>
                    <Link to="/agent-marketplace" onClick={scrollToTop}>
                      <li>Agent Marketplace</li>
                    </Link>
                    <Link to="/channel-manager" onClick={scrollToTop}>
                      <li>Channel Manager</li>
                    </Link>
                    <Link to="/aitripplanner" onClick={scrollToTop}>
                      <li>AI Trip Planner</li>
                    </Link>
                    <Link to="/payment-solutions" onClick={scrollToTop}>
                      <li>Payment Solutions</li>
                    </Link>
                  </ul>
                </div>
              )}
            </li>
            <li
              onClick={() => handleMainItemClick("resources")}
              className={activeItem === "resources" ? "active" : ""}
            >
              Resources
              {activeItem === "resources" && (
                <div className="sub-dropdown">
                  <ul>
                  <Link to="/coming-soon" onClick={scrollToTop}>
                      <li>Integrations</li>
                    </Link>
                    <Link to="/coming-soon" onClick={scrollToTop}>
                      <li>Onboarding APIs</li>
                    </Link>
                    <Link to="/coming-soon" onClick={scrollToTop}>
                      <li>Developer Docs</li>
                    </Link>
                    <Link to="/coming-soon" onClick={scrollToTop}>
                      <li>API Reference</li>
                    </Link>
                  </ul>
                </div>
              )}
            </li>
            <li>
              <Link to="/pricing" onClick={scrollToTop}>
                Pricing
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default MobileNavbar;
